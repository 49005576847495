import gsap from 'gsap';
import { SplitText } from "../gsap/SplitText";

export default class Intro {

  private _intro_hidden: boolean = false;
  private _text_length: number;
  private _intro_element: HTMLElement = document.querySelector( '.c-intro' );
  

  constructor() {

    const session_storage = window.sessionStorage;
    const intro_shown: string = session_storage.getItem( 'intro_shown' );

    if ( this._intro_element && !intro_shown) {
    // if ( this._intro_element) {

      const text_element: HTMLParagraphElement = document.querySelector( '.c-intro__text' );
      const images: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-intro__image' );
      if ( text_element ) {

        this._intro_element.classList.add( 's-ready' );
        this._intro_element.addEventListener( 'click', () => this.hideIntro( 0 ) );
        const text: String = text_element.innerText;
        this._text_length = text.length;
        
        gsap.registerPlugin( SplitText );
        
        document.body.classList.add( 's-show-content' );
        let split_text = new SplitText( '.c-intro__text', { type: "words,chars" } );
        // @ts-ignore
        const tl = gsap.timeline( { onComplete: () => this.hideIntro() } );
        tl.from( split_text.chars, { duration: .01, opacity: 0, stagger: .02 } );
        
        if ( images && images.length ) {

          const delay: number = this._text_length * .03;
          tl.to( images, { duration: .1, opacity: 1, stagger: .3 }, delay );
          
        }
    
        session_storage.setItem( 'intro_shown', 'true' );

      }
      
    } else {

      // this.hideIntro();
      document.body.classList.add( 's-no-intro' );
      // document.body.classList.add( 's-show-content' );
      if ( this._intro_element ) this._intro_element.classList.add( 's-completed' );

    }

  }


  public showContent() {

    if ( !this._intro_hidden ) {

      document.body.classList.add('s-show-content');
      this._intro_hidden = true;

    }

  }
  

  private hideIntro( delay: number = 1) {
    
    // if ( !delay && delay !== 0 ) delay = this._text_length * .03;
    // if ( !delay && delay !== 0 ) delay = 2;
    // gsap.to( this._intro_element, { duration: .5, delay: delay, y: "-100%", onComplete: () => {
    gsap.to( this._intro_element, { autoAlpha: 0, duration: 1, delay: delay, onComplete: () => {
      
      this._intro_element.classList.add( 's-completed' );

    } } );

  }

}