// import { TweenMax, Power2, Power4, Circ } from 'gsap';
import gsap, { Power2 } from 'gsap';
// @ts-ignore
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";

export class PageTransition {

    public transitionType: string = '';
    
    private _body:HTMLBodyElement = <HTMLBodyElement>document.body;
    
    private _transition_container: HTMLElement = <HTMLElement>document.querySelector( '.l-main' );
    private _head_container: HTMLElement = <HTMLElement>document.querySelector( 'head' );
    private _content_container: HTMLElement = <HTMLElement>document.querySelector( '.l-main' );
    private _header_container: HTMLElement = <HTMLElement>document.querySelector( '.c-header__inner' );
    private _language_menu_container: HTMLElement = <HTMLElement>document.querySelector( '.c-header__language' );
    private _footer_container: HTMLElement = <HTMLElement>document.querySelector( '.c-footer' );
    private _calendar_day_container: HTMLElement;
    private _content_hidden: boolean = false;

    private closeMenu: Event = document.createEvent( "Event" );
    // private openOverlay: Event = document.createEvent( "Event" );
    // private closeOverlay: Event = document.createEvent( "Event" );
    private newContentInserted: Event = document.createEvent( "Event" );
    private contentUpdated: CustomEvent = document.createEvent( "CustomEvent" );
    private contentFullyRevealed: CustomEvent = document.createEvent( "CustomEvent" );


    constructor() {

        // @ts-ignore
        gsap.registerPlugin( ScrollToPlugin );

        this.closeMenu.initEvent( "closeMenu", false, true );
        // this.openOverlay.initEvent( "openOverlay", false, true );
        // this.closeOverlay.initEvent( "closeOverlay", false, true );
        this.newContentInserted.initEvent( "newContentInserted", false, true );
        this.contentUpdated.initEvent( "contentUpdated", false, true );
        this.contentFullyRevealed.initEvent( "contentFullyRevealed", false, true );

    }


    public hideContent( event?: CustomEvent, transition_type?: string ) {
        
        this._body.classList.add( 's-transition' );

        window.dispatchEvent( this.closeMenu );

        this.transitionType = transition_type && transition_type.length ? transition_type : '';

        if ( event ) {

            const target: HTMLElement = event.detail.target;
            if ( target.classList.contains( 'tribe-events-calendar-month__day-date-link' ) ) {
                this.transitionType = 'calendarDay';
            }
        }

        if ( this.transitionType === 'calendarDay' ) {
            
            this._calendar_day_container = document.querySelector( '.c-calendar__day' );

            gsap.to( this._calendar_day_container, { duration: .5, opacity: 0, ease: "power2.inOut", onComplete: () => {

                window.scrollTo(0,0);
                this._content_hidden = true;
            
            } } );

        } else {
            
            gsap.to( this._transition_container, { duration: .5, opacity: 0, ease: "power2.inOut", onComplete: () => {
                
                // if ( this.transitionType !== "history" ) window.scrollTo(0,0);
                this._content_hidden = true;
                
            } } );
            
        }

        this._content_container.style.pointerEvents = 'none';

        this.unloadVideos();

    }


    private loadNewAssets( selector: string, new_markup: string, existing_container: HTMLElement, trigger_load: boolean = false ) {

        const temp_constainer: HTMLElement = document.createElement( 'div' );
        temp_constainer.innerHTML = new_markup;
        const asset_elements: NodeListOf<HTMLLinkElement> = temp_constainer.querySelectorAll( selector );
        if ( existing_container && asset_elements && asset_elements.length ) {

            const asset_elements_loaded: NodeListOf<HTMLElement> = document.querySelectorAll( selector );
            let new_assets_markup: string = "";
            for ( let i: number = 0; i < asset_elements.length; i++ ) {

                let loaded: boolean = false;
                for ( let j: number = 0; j < asset_elements_loaded.length; j++ ) {

                    if ( asset_elements[ i ].id === asset_elements_loaded[ j ].id ) loaded = true;

                }

                if ( !loaded ) {
                    document.head.appendChild( asset_elements[ i ] )
                    new_assets_markup += asset_elements[ i ].outerHTML;
                }

            }

            // if ( new_assets_markup && new_assets_markup.length ) existing_container.insertAdjacentHTML( 'beforeend', new_assets_markup );

            if ( trigger_load ) {
                // window.dispatchEvent("load");
                // console.log(new_assets_markup);
            }

        }

    }


    public injectContent() {

        if ( this._content_hidden ) {

            let body_classes: string = history.state.bodyClasses;
            
            this._body.classList.remove( 's-transition' );

            // console.log(history.state.markup);
            // this.loadNewAssets( "link[href*='/plugins/events-calendar-pro/'], link[href*='/plugins/the-events-calendar/']", history.state.markup[0], this._head_container );
            // this.loadNewAssets( "script[src*='/plugins/events-calendar-pro/'], script[src*='/plugins/the-events-calendar/']", history.state.markup[1], this._body, true );
            
            if ( this._body && ( body_classes || body_classes === '' ) ) {
                
                body_classes = body_classes.replace('s-initial-reveal', '');
                body_classes = `${body_classes} s-show-content`;
                const is_touch: boolean = this._body.classList.contains( 'touch-device' );
                body_classes = ( is_touch ) ? `${body_classes} touch-device` : body_classes;
                
                this._body.setAttribute( 'class', body_classes );
                
            }

            const new_header_content = history.state.markup[3];
            if ( this._header_container && new_header_content ) {
        
                this._header_container.innerHTML = new_header_content;
    
            }

            const language_menu_content = history.state.markup[4];
            if ( this._language_menu_container && language_menu_content ) {
        
                this._language_menu_container.innerHTML = language_menu_content;
    
            }

            const new_footer_content = history.state.markup[5];
            if ( this._footer_container && new_footer_content ) {
        
                this._footer_container.innerHTML = new_footer_content;
    
            }
            
            if ( this.transitionType === 'calendarDay' ) {
                
                const day_content = history.state.markup[6];
                if ( this._calendar_day_container && day_content ) {
            
                    this._calendar_day_container.classList.remove( 'c-calendar__day--intro' );
                    this._calendar_day_container.innerHTML = day_content;
                    
                }
                
            } else {
                
                const new_content = history.state.markup[2];
                if ( this._content_container && new_content ) {
            
                    this._content_container.innerHTML = new_content;
        
                }

            }

            const scroll_y: number = history.state.scrollTop ? history.state.scrollTop : 0;
            window.scrollTo( 0, scroll_y );
            
            setTimeout(() => {
                
                // @ts-ignore
                window.SCROLL_DIRECTION = 'down';
                window.dispatchEvent( this.newContentInserted );
                window.dispatchEvent( this.contentUpdated );
                
            }, 200);

        } else {
            
            setTimeout( () => {
            
                this.injectContent();
            
            }, 100 );
        
        }

    }


    public showContent() {

        if ( this.transitionType === 'calendarDay' ) {

            // @ts-ignore
            gsap.to( this._calendar_day_container, { duration: 1, delay: .2, opacity: 1, ease: "power2.inOut", onComplete: () => {
                
                window.dispatchEvent( this.contentFullyRevealed );    

            } } );

        } else {
   
            // @ts-ignore
            gsap.to( this._transition_container, { duration: 1, delay: .2, opacity: 1, ease: "power2.inOut", onComplete: () => {
                
                window.dispatchEvent( this.contentFullyRevealed );    

            } } );

        }

        this._content_container.style.pointerEvents = 'auto';
        this._content_hidden = false;
    
    }


    private unloadVideos() {

        const videos: NodeListOf<HTMLVideoElement> = document.querySelectorAll( 'video' );
        for ( let i: number = 0; i < videos.length; i++ ) {

            const source_elements: NodeListOf<HTMLSourceElement> = videos[ i ].querySelectorAll( 'source' );
            for ( let j: number = 0; j < source_elements.length; j++ ) {

                source_elements[ j ].removeAttribute('src');

            }

            videos[ i ].pause();
            videos[ i ].preload = 'none';
            videos[ i ].setAttribute( 'preload', 'none' );

        }

    }

}