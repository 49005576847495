import * as ES6Promise from 'es6-promise';
ES6Promise.polyfill();

import Dev from './global/Dev';
import Header from './global/Header';
import Intro from './global/Intro';
// import Menu from './global/Menu';
import SearchModal from './global/SearchModal';
import { HistoryManager } from './andreasoby/controllers/HistoryManager';
import { PageTransition } from './andreasoby/controllers/PageTransition';
import LazyLoad from './andreasoby/controllers/Lazyload';

interface ModuleToLoad {
  selector: string;
  promise: any;
}

// @ts-ignore
window.loadPage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Page" */ './pages/Page');
};
//@ts-ignore
window.loadSlideshow = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "slideshow" */ './modules/Slideshow'
  );
};
//@ts-ignore
window.loadMedia = async (): Promise<any> => {
  return await import(/* webpackChunkName: "media" */ './modules/Media');
};

//@ts-ignore
window.loadMenu = async (): Promise<any> => {
  return await import(/* webpackChunkName: "menu" */ './global/Menu');
};

//@ts-ignore
window.loadRoomRoomAbout = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "RoomRoomAbout" */ './global/RoomRoomAbout'
  );
};

//@ts-ignore
window.loadRrExhibitions = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "RrExhibitions" */ './pages/RrExhibitions'
  );
};

//@ts-ignore
window.loadImageAndCaption = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "imageAndCaption" */ './modules/ImageAndCaption'
  );
};
//@ts-ignore
window.loadMediaAndCaption = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "mediaAndCaption" */ './modules/MediaAndCaption'
  );
};
//@ts-ignore
window.loadHeadline = async (): Promise<any> => {
  return await import(/* webpackChunkName: "headline" */ './modules/Headline');
};
//@ts-ignore
window.loadAudio = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Audio" */ './modules/Audio');
};
//@ts-ignore
window.loadText = async (): Promise<any> => {
  return await import(/* webpackChunkName: "text" */ './modules/Text');
};
//@ts-ignore
window.loadTextColumns = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "textColumns" */ './modules/TextColumns'
  );
};
//@ts-ignore
window.loadPageColumns = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "pageColumns" */ './modules/PageColumns'
  );
};
//@ts-ignore
window.loadCalendar = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "calendar" */ './modules/calendar/Calendar'
  );
};
//@ts-ignore
window.loadClock = async (): Promise<any> => {
  return await import(/* webpackChunkName: "clock" */ './modules/Clock');
};
//@ts-ignore
window.loadEventTeaser = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "eventTeaser" */ './modules/EventTeaser'
  );
};
//@ts-ignore
window.loadPersons = async (): Promise<any> => {
  return await import(/* webpackChunkName: "persons" */ './modules/Persons');
};
//@ts-ignore
window.loadLogos = async (): Promise<any> => {
  return await import(/* webpackChunkName: "logos" */ './modules/Logos');
};

//@ts-ignore
window.loadLinks = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Links" */ './modules/Links');
};

//@ts-ignore
window.loadCalendarIntro = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "calendarIntro" */ './modules/calendar/CalendarIntro'
  );
};
//@ts-ignore
window.loadFrontPage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "FrontPage" */ './pages/Front');
};
//@ts-ignore
window.loadRrFrontPage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "RrFrontPage" */ './pages/RrFront');
};
//@ts-ignore
window.loadProfilesPage = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ProfilesPage" */ './pages/Profiles'
  );
};
//@ts-ignore
window.loadProfilePage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ProfilePage" */ './pages/Profile');
};
//@ts-ignore
window.loadMediaArchivePage = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "MediaArchivePage" */ './pages/MediaArchive'
  );
};
//@ts-ignore
window.loadMediaItemPage = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "MediaItemPage" */ './pages/MediaItem'
  );
};
//@ts-ignore
window.loadMediaItemTeaser = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "MediaItemTeaser" */ './teasers/MediaItemTeaser'
  );
};
//@ts-ignore
window.loadProgramTeaser = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ProgramTeaser" */ './teasers/ProgramTeaser'
  );
};
//@ts-ignore
window.loadProfileTeaser = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ProfileTeaser" */ './teasers/ProfileTeaser'
  );
};
//@ts-ignore
window.loadNewsTeaser = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "NewsTeaser" */ './teasers/NewsTeaser'
  );
};
//@ts-ignore
window.loadListResidents = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ListResidents" */ './modules/ListResidents'
  );
};
//@ts-ignore
window.loadProgramsPage = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ProgramsPage" */ './pages/Programs'
  );
};
//@ts-ignore
window.loadAccordion = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Accordion" */ './pages/Accordion');
};
//@ts-ignore
window.loadNewsPost = async (): Promise<any> => {
  return await import(/* webpackChunkName: "NewsPost" */ './pages/NewsPost');
};
//@ts-ignore
window.loadNewsArchive = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "NewsArchive" */ './pages/NewsArchive'
  );
};
//@ts-ignore
window.loadRelatedPosts = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "RelatedPosts" */ './modules/RelatedPosts'
  );
};
//@ts-ignore
window.loadProgramsParticipation = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ProgramsParticipation" */ './modules/ProgramsParticipation'
  );
};
//@ts-ignore
window.loadScrollToMenu = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ScrollToMenu" */ './modules/ScrollToMenu'
  );
};

//@ts-ignore
window.loadSelectedProfiles = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "SelectedProfiles" */ './modules/SelectedProfiles'
  );
};
//@ts-ignore
window.loadLatestNews = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "LatestNews" */ './modules/LatestNews'
  );
};
//@ts-ignore
window.loadNewsTicker = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "NewsTicker" */ './modules/NewsTicker'
  );
};
//@ts-ignore
window.loadProgramsSlideshow = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ProgramsSlideshow" */ './modules/ProgramsSlideshow'
  );
};
//@ts-ignore
window.loadMediaItemsSlideshow = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "MediaItemsSlideshow" */ './modules/MediaItemsSlideshow'
  );
};
//@ts-ignore
window.loadUpcomingEvents = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "UpcomingEvents" */ './modules/UpcommingEvents'
  );
};
//@ts-ignore
window.loadPdf = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Pdf" */ './modules/Pdf');
};
//@ts-ignore
window.loadModalLink = async (): Promise<any> => {
  return await import(
    /* webpackChunkName: "ModalLink" */ './modules/ModalLink'
  );
};

class Main {
  private _history_manager: HistoryManager;
  private _page_transition: PageTransition;
  private _lazy_load: LazyLoad;
  private _lazy_load_intiated: boolean = false;

  private _lang_switcher_links: NodeListOf<HTMLAnchorElement>;

  private _header: Header;
  private _intro: Intro;
  // private _menu: Menu;

  constructor() {
    document.addEventListener('DOMContentLoaded', () => this.init());
  }

  private init() {
    if (!document.body.classList.contains('logged-in')) {
      this._history_manager = new HistoryManager(
        [
          'head',
          'body',
          '.l-main',
          '.c-header__inner',
          '.c-header__language',
          '.c-footer',
          '.c-calendar__day',
        ],
        '',
        '.tribe-events-header a, .c-calendar__nav a'
      );
      // this._history_manager = new HistoryManager( [ '.l-main', '.c-header__inner', '.c-header__language', '.c-footer', '.c-calendar__day' ], "", ".tribe-events-header a, .c-calendar__nav a" );

      this._page_transition = new PageTransition();

      this.setupListeners();
    }

    this._lang_switcher_links = document.querySelectorAll(
      '.c-header__language__link'
    );
    this._lazy_load = new LazyLoad();

    this.setupContent();
    this.checkIfTouch();

    this._header = new Header();
    this._intro = new Intro();
    // this._menu = new Menu();
    new SearchModal();

    new Dev();
    // new Newsletter();

    // Add custom cursor if user isn't using a touch device
    if (this.checkIfTouch()) {
      document.body.classList.add('touch-device');
    }
  }

  private checkIfTouch() {
    try {
      document.createEvent('TouchEvent');
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      return true;
    } catch (e) {
      return false;
    }
  }

  private setupListeners() {
    window.addEventListener('ajaxLinkClicked', (event: CustomEvent) => {
      this._page_transition.hideContent(event);
    });

    window.addEventListener('ajaxSucceeded', (event: Event) =>
      this.ajaxSucceeded(event)
    );
    window.addEventListener('newContentInserted', () => this.setupContent());
    window.addEventListener('contentUpdated', () => this.updateContent());
    window.addEventListener('contentFullyRevealed', () =>
      this.contentFullyRevealed()
    );
    window.addEventListener('newMonthDisplayed', () => {
      this._history_manager.setupNewLinks();
    });

    window.addEventListener('popstate', (event: PopStateEvent) => {
      this._page_transition.hideContent(null, 'history');
      this._page_transition.injectContent();
    });
  }

  private ajaxSucceeded(event: Event) {
    this._page_transition.injectContent();
    // this._menu = new Menu();
  }

  private updateContent() {
    this._header.updateLinks();
    this._history_manager.setupNewLinks();
  }

  private contentFullyRevealed() {}

  private setupContent() {
    if (document.body.classList.contains('tribe-js')) {
      for (let i: number = 0; i < this._lang_switcher_links.length; i++) {
        this._lang_switcher_links[i].classList.add('no-ajax');
      }
    } else {
      for (let i: number = 0; i < this._lang_switcher_links.length; i++) {
        this._lang_switcher_links[i].classList.remove('no-ajax');
      }
    }

    const modules: Array<ModuleToLoad> = [
      { selector: '.c-page', promise: 'loadPage' },
      { selector: '.c-calendar__month', promise: 'loadCalendarMonth' },
      { selector: '.c-calendar__day', promise: 'loadCalendarDay' },
      { selector: '.c-calendar__day--intro', promise: 'loadCalendarIntro' },
      { selector: '.c-slideshow', promise: 'loadSlideshow' },
      { selector: '.c-media', promise: 'loadMedia' },
      { selector: '.c-main-nav', promise: 'loadMenu' },
      { selector: '.c-image-and-caption', promise: 'loadImageAndCaption' },
      { selector: '.c-media-and-caption', promise: 'loadMediaAndCaption' },
      { selector: '.c-text', promise: 'loadText' },
      { selector: '.c-text-columns', promise: 'loadTextColumns' },
      { selector: '.c-page__columns', promise: 'loadPageColumns' },
      { selector: '.c-headline', promise: 'loadHeadline' },
      { selector: '.c-audio', promise: 'loadAudio' },
      { selector: '.tribe-events', promise: 'loadCalendar' },
      { selector: '.c-clock__toggle', promise: 'loadClock' },
      { selector: '.c-event-teaser', promise: 'loadEventTeaser' },
      { selector: '.c-persons', promise: 'loadPersons' },
      { selector: '.c-logos', promise: 'loadLogos' },
      { selector: '.c-links', promise: 'loadLinks' },
      { selector: '.c-front', promise: 'loadFrontPage' },
      { selector: '.c-rr-front', promise: 'loadRrFrontPage' },
      { selector: '.c-rr-exhibitions', promise: 'loadRrExhibitions' },
      { selector: '.c-profile', promise: 'loadProfilePage' },
      { selector: '.c-media-item', promise: 'loadMediaItemPage' },
      { selector: '.c-media-archive', promise: 'loadMediaArchivePage' },
      { selector: '.c-profiles', promise: 'loadProfilesPage' },
      { selector: '.c-programs', promise: 'loadProgramsPage' },
      { selector: '.c-media-item-teaser', promise: 'loadMediaItemTeaser' },
      { selector: '.c-profile-teaser', promise: 'loadProfileTeaser' },
      { selector: '.c-program-teaser', promise: 'loadProgramTeaser' },
      { selector: '.c-accordion', promise: 'loadAccordion' },
      { selector: '.c-news-post', promise: 'loadNewsPost' },
      { selector: '.c-list-residents', promise: 'loadListResidents' },
      { selector: '.c-news-teaser', promise: 'loadNewsTeaser' },
      { selector: '.c-news-archive', promise: 'loadNewsArchive' },
      { selector: '.c-related-posts', promise: 'loadRelatedPosts' },
      {
        selector: '.c-programs-participation',
        promise: 'loadProgramsParticipation',
      },
      { selector: '.c-selected-profiles', promise: 'loadSelectedProfiles' },
      { selector: '.c-scroll-to-menu', promise: 'loadScrollToMenu' },
      { selector: '.c-latest-news', promise: 'loadLatestNews' },
      { selector: '.c-news-ticker', promise: 'loadNewsTicker' },
      { selector: '.c-programs-slideshow', promise: 'loadProgramsSlideshow' },
      {
        selector: '.c-media-items-slideshow',
        promise: 'loadMediaItemsSlideshow',
      },
      { selector: '.c-upcoming-events', promise: 'loadUpcomingEvents' },
      { selector: '.c-pdf', promise: 'loadPdf' },
      { selector: '.c-modal-link', promise: 'loadModalLink' },
      { selector: '.room-room-about', promise: 'loadRoomRoomAbout' },
    ];

    const queue: Array<any> = [];
    for (let i: number = 0; i < modules.length; i++) {
      const element: HTMLElement = document.querySelector(modules[i].selector);
      //@ts-ignore

      if (element && typeof window[modules[i].promise] === 'function')
        //@ts-ignore
        queue.push(window[modules[i].promise]());
    }

    Promise.all(queue).then((modules: any) => {
      for (let i: number = 0; i < modules.length; i++) {
        // Get dynamic constructor name
        const name: string = Object.keys(modules[i])[0];
        new modules[i][name]();
      }

      if (!this._lazy_load.intiated) this._lazy_load.init();

      if (!this._page_transition) {
        this._page_transition = new PageTransition();
      }

      this._lazy_load.updateContent();
      this._intro.showContent();
      this._page_transition.showContent();
    });
  }
}

new Main();
