export default class SearchModal {
    private _modal_container: HTMLElement;

    constructor() {
        this._modal_container = document.querySelector('.search-modal-container');

        if(!this._modal_container) {
            return;
        }

        this.init();
    }

    private init() {
        window.addEventListener('openSearchModal', this.openModal.bind(this));
        window.addEventListener('closeSearchModal', this.closeModal.bind(this));
        
        this._modal_container.querySelector('.close-btn')?.addEventListener('click', this.closeModal.bind(this));
    }
    
    private openModal() {
        this._modal_container.classList.add('active');
    }

    private closeModal() {
        this._modal_container.classList.remove('active');
    }
}